import { useEffect, useState, useCallback } from 'react';
import { getFileMimeType, getFileName } from '../utils/file';

export enum DownloadError {
  INVALID_RESPONSE = 'Invalid response',
  CONTENT_EXPIRED = 'Content expired',
}

const useDownloadContent = (url: string, type: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<any>();

  const handleDownloadProgress = (originalResponse: Response) => {
    let loadedBytes = 0;
    const contentLength = originalResponse.headers.get('content-length') || '';
    const contentType = originalResponse.headers.get('content-type') || '';
    const totalBytes = parseInt(contentLength, 10);
    const { body } = originalResponse;

    if (body === null) {
      return null;
    }

    return new Response(
      new ReadableStream({
        async start(controller) {
          const reader = body.getReader();
          let isDownloading = true;
          while (isDownloading) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader.read();
            if (done) {
              isDownloading = false;
            } else {
              loadedBytes += value!.byteLength;
              setProgress(Math.floor((loadedBytes / totalBytes) * 100));
              controller.enqueue(value);
            }
          }
          controller.close();
        },
      }),
      {
        headers: { 'content-type': contentType },
        status: originalResponse.status,
      }
    );
  };

  const downloadContent = useCallback(async (url: string, type: string) => {
    try {
      setLoading(true);
      const originalResponse = await fetch(url);
      const response = handleDownloadProgress(originalResponse);
      if (response === null) {
        setError(DownloadError.INVALID_RESPONSE);
        return;
      }
      if (response.status === 403) {
        setError(DownloadError.CONTENT_EXPIRED);
        return;
      }

      if (response.ok) {
        const responseBlob = await response.blob();
        const fileBlob = new File([responseBlob], getFileName(type), {
          type: getFileMimeType(type),
        });
        setFile(fileBlob);
      } else {
        setError(response.statusText);
      }
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await downloadContent(url, type);
    })();
  }, [downloadContent, url, type]);

  return { loading, progress, file, error };
};

export default useDownloadContent;
