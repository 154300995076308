import React from 'react';
import './SharingPolicyLink.css';

const SharingPolicyLink = () => (
  <footer>
    <h1 className="share-acceptable-use-policy">
      <a href="./terms-and-conditions" target="_blank" rel="noopener noreferrer">
        Acceptable use policy
      </a>
    </h1>
  </footer>
);

export default SharingPolicyLink;
