import React from 'react';
import './MediaInstruction.css';
import shareButton from '../assets/share-button.png';
import saveButton from '../assets/save-button.png';
import { getFileUrl } from '../utils/file';

export enum MediaComponentButtonType {
  Share,
  Save,
  Audio,
}

type MediaComponentButtonProps = {
  type: MediaComponentButtonType;
  file?: File;
  src?: any;
  onClick?: () => void;
};

const MediaComponentButton = ({ type, src, file, onClick }: MediaComponentButtonProps) => {
  if (type === MediaComponentButtonType.Share) {
    return (
      <input
        type="image"
        src={shareButton}
        className="media-component-button"
        alt="share"
        onClick={async () => {
          onClick?.();
        }}
      />
    );
  }

  if (type === MediaComponentButtonType.Save) {
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <a href={getFileUrl(file)} download={file?.name}>
        <img src={saveButton} className="media-component-button" alt="" />
      </a>
    );
  }

  if (type === MediaComponentButtonType.Audio) {
    return (
      <input
        type="image"
        src={src}
        alt="Mute video"
        className="media-component-button"
        onClick={() => {
          onClick?.();
        }}
      />
    );
  }

  return <div />;
};

export default MediaComponentButton;
