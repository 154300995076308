import React, { ReactNode } from 'react';
import logo from '../assets/logo.svg';
import './Page.css';

type PageProps = {
  content: ReactNode;
};

const Page = ({ content }: PageProps) => (
  <div className="page">
    <header className="page-header">
      <img src={logo} className="page-logo" alt="logo" />
      {content}
    </header>
  </div>
);

export default Page;
