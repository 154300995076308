import navigateToContent from './navigator';

export const isShareContentSupported = (): boolean => {
  try {
    const ua = navigator.userAgent;
    const nav: any = window.navigator;
    return !ua.toLowerCase().includes('firefox') && nav.canShare !== undefined;
  } catch (error) {
    return false;
  }
};

export const shareContent = async (contentUrl: string, file: File | undefined) => {
  if (!isShareContentSupported() || file === undefined) {
    navigateToContent(contentUrl);
  }
  try {
    const filesArray = [file];
    const nav: any = window.navigator;
    await nav.share({
      files: filesArray,
    });
  } catch (error) {
    if (error instanceof DOMException && error.name === 'AbortError') {
      // ignore that, user just cancelled prompt
      return;
    }
    navigateToContent(contentUrl);
  }
};
