import React from 'react';
import './SharingPolicy.css';

const SharingPolicyComponent = () => (
  <div className="use-policy-container">
    <div className="use-policy-header">Acceptable Use Policy</div>
    <div className="use-policy-subheader">
      VideoBooth is a fun and friendly place to be. You agree that you will not use this service to:
    </div>
    <ul className="use-policy-terms">
      <li>Create and post illegal, obscene, or harmful material</li>
      <li>Violate anyone&apos;s rights or cause distress</li>
      <li>Share material that you don&apos;t have the rights to use</li>
      <li>Use the service for any commercial purpose</li>
      <li>Interfere with the operation or security of Sky&apos;s TV products</li>
      <li>Use the service in a way that is improper or offensive</li>
    </ul>
  </div>
);

export default SharingPolicyComponent;
