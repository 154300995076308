import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Page from './pages/Page';
import SharingPolicyComponent from './components/SharingPolicyComponent';
import MediaComponent from './components/MediaComponent';

const App: React.FunctionComponent = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Page content={<MediaComponent />} />} />
      <Route path="/terms-and-conditions" element={<Page content={<SharingPolicyComponent />} />} />
    </Routes>
  </Router>
);

export default App;
