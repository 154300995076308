import moment from 'moment';

export const getFileName = (type: string): string => {
  const fileTimestamp = moment().format('YYYYMMDDhhmmss');

  if (type === 'image') {
    return `videobooth-image-${fileTimestamp}.jpg`;
  }
  if (type === 'video') {
    return `videobooth-video-${fileTimestamp}.mp4`;
  }

  return `videobooth_unknown-${fileTimestamp}.file`;
};

export const getFileMimeType = (type: string): string => {
  if (type === 'image') {
    return 'image/jpeg';
  }
  if (type === 'video') {
    return 'video/mp4';
  }

  return 'application/octet-stream';
};

export const getFileUrl = (file: File | undefined): string => {
  if (file === undefined) {
    return '';
  }

  return URL.createObjectURL(file!);
};
