import React, { MutableRefObject, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { shareContent } from '../utils/share';
import MediaInstruction from './MediaInstruction';
import './MediaComponent.css';
import errorImage from '../assets/errorImage.png';
import clockImage from '../assets/clockImage.png';
import volumeOff from '../assets/volumeOff.png';
import volumeOn from '../assets/volumeOn.png';
import useDownloadContent, { DownloadError } from '../hooks/downloadContentHook';
import { getFileUrl } from '../utils/file';
import MediaComponentButton, { MediaComponentButtonType } from './MediaComponentButton';
import {
  isBrowserSupported,
  isDesktopDevice,
  isIOSDevice,
  shouldShowSaveButton,
  shouldShowShareButton,
} from '../utils/device';
import SharingPolicyLink from './SharingPolicyLink';

const MediaComponent = () => {
  const videoRef = useRef() as MutableRefObject<HTMLVideoElement>;
  const query = new URLSearchParams(useLocation().search);
  const contentUrl = query.get('url') || '';
  const contentType = query.get('type') || '';
  const isContentValid = contentUrl !== '' && contentType !== '';
  const { loading, progress, file, error } = useDownloadContent(contentUrl, contentType);
  const [isVideoMuted, setIsVideoMuted] = useState<boolean>(true);

  if (isDesktopDevice() || !isBrowserSupported()) {
    return (
      <div className="media-component-error-container">
        <img
          src={errorImage}
          className="media-component-image-error"
          style={{ maxWidth: '250px', margin: '48px' }}
          alt="content"
        />
        <MediaInstruction />
      </div>
    );
  }

  if (error || !isContentValid) {
    if (error === DownloadError.CONTENT_EXPIRED) {
      return (
        <div className="media-component-error-container">
          <img src={clockImage} className="media-component-image-error" alt="content" />
          <h1 className="media-component-error">Time flies</h1>
          <h3 className="media-instruction-message">
            Your 15 mins are up. <br />
            We’ll have to do this all over again.
          </h3>
        </div>
      );
    }
    return (
      <div className="media-component-error-container">
        <img src={errorImage} className="media-component-image-error" alt="content" />
        <h1 className="media-component-error">Oh, no!</h1>
        <h3 className="media-instruction-message">
          Something went wrong. <br /> It’s not you; it’s us. <br /> Please try again.
        </h3>
      </div>
    );
  }

  if (loading) {
    return <p className="media-component-loading">Loading {progress}%</p>;
  }

  const toggleAudio = () => {
    videoRef.current.play();
    videoRef.current.muted = !videoRef.current.muted;
    setIsVideoMuted(videoRef.current.muted);
  };

  // TODO: Change video tag after iOS 16 release. https://agile.at.sky/browse/CH-5263
  return (
    <div className="media-component-content">
      <div className="media-component-media">
        {contentType === 'image' && (
          <img src={getFileUrl(file)} className="media-component-image" alt="content" />
        )}

        {contentType === 'video' && (
          <video ref={videoRef} className="media-component-video" autoPlay muted playsInline loop>
            {isIOSDevice && <source src={contentUrl} type="video/mp4" />}
            {!isIOSDevice && <source src={getFileUrl(file)} type="video/mp4" />}
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      {contentType === 'video' && (
        <MediaComponentButton
          type={MediaComponentButtonType.Audio}
          src={isVideoMuted ? volumeOff : volumeOn}
          onClick={() => toggleAudio()}
        />
      )}
      {shouldShowShareButton() && (
        <MediaComponentButton
          type={MediaComponentButtonType.Share}
          onClick={async () => {
            await shareContent(contentUrl!, file);
          }}
        />
      )}
      {shouldShowSaveButton() && (
        <MediaComponentButton type={MediaComponentButtonType.Save} file={file} />
      )}
      <div>
        <MediaInstruction />
        <SharingPolicyLink />
      </div>
    </div>
  );
};

export default MediaComponent;
