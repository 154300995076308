import {
  isAndroid,
  isDesktop,
  isIOS,
  isMacOs,
  isWindows,
  browserVersion,
  isMobileSafari,
  isChrome,
  isMobile,
  isSamsungBrowser,
} from 'react-device-detect';

const minSupportedBrowserVersionChrome = 76;
const minSupportedBrowserVersionSamsung = 11;
const minSupportedMobileBrowserVersionSafari = 15;

export const isBrowserVersionSupported = (): boolean => {
  if (isChrome && Number(browserVersion) >= minSupportedBrowserVersionChrome) {
    return true;
  }
  if (isSamsungBrowser && Number(browserVersion) >= minSupportedBrowserVersionSamsung) {
    return true;
  }
  if (isMobileSafari && Number(browserVersion) >= minSupportedMobileBrowserVersionSafari) {
    return true;
  }

  return false;
};

const isOSSupported = (): boolean => isMobile && (isAndroid || isIOS);

export const isBrowserSupported = (): boolean => isChrome || isMobileSafari || isSamsungBrowser;

export const shouldShowShareButton = (): boolean => isOSSupported() && isBrowserVersionSupported();

export const shouldShowSaveButton = (): boolean =>
  (isAndroid && isBrowserSupported()) ||
  (isIOS && isBrowserSupported() && !isBrowserVersionSupported());

export const isDesktopDevice = (): boolean => isMacOs || isWindows || isDesktop;

export const isIOSDevice = isIOS;
