import React from 'react';
import {
  isDesktopDevice,
  shouldShowShareButton,
  isBrowserVersionSupported,
  isBrowserSupported,
} from '../utils/device';
import './MediaInstruction.css';

const MediaInstruction = () => {
  const shareNote = 'Share or save';
  const saveNote = 'Save your file';

  const unsupportedDeviceFirstLine = 'Sorry, we don’t support this device.';
  const unsupportedDeviceSecondLine = 'Try your Mobile.';

  const unsupportedBrowserFirstLine = 'Unfortunately we don’t support this browser ';
  const unsupportedBrowserSecondLine =
    'VideoBooth works better with Chrome or your native browser.';

  const unsupportedVersionSecondLine =
    'This browser is a little old.\nFingers crossed, you can save.';

  if (isDesktopDevice()) {
    return (
      <div>
        <h1 className="media-instruction-title">{unsupportedDeviceFirstLine}</h1>
        <h3 className="media-instruction-message">{unsupportedDeviceSecondLine}</h3>
      </div>
    );
  }

  if (!isBrowserSupported()) {
    return (
      <div>
        <h1 className="media-instruction-title">{unsupportedBrowserFirstLine}</h1>
        <h3 className="media-instruction-message">{unsupportedBrowserSecondLine}</h3>
      </div>
    );
  }

  if (!isBrowserVersionSupported()) {
    return (
      <div>
        <h3 className="media-instruction-message">{unsupportedVersionSecondLine}</h3>
      </div>
    );
  }

  return (
    <div>
      <h1 className="media-instruction-title">
        {!shouldShowShareButton() && `${saveNote}`}
        {shouldShowShareButton() && `${shareNote}`}
      </h1>
    </div>
  );
};

export default MediaInstruction;
